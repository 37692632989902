import { createRouter, createWebHistory } from "vue-router";
import { useStore } from "../useStore"; // Remplacez par le bon chemin vers votre store Pinia
import { supabase } from "../../supabase";
import TodoList from "@/components/Cominterne/TodoList.vue";

// Remplacer les imports statiques par des imports dynamiques
const InventoryView = () => import("../views/InventoryView.vue");
const ShippingHistoryView = () =>
  import("../views/ecommerce/ShippingHistoryView.vue");
const OrderSearchView = () => import("../views/ecommerce/OrderSearchView.vue");
const FreeShippinghView = () =>
  import("../views/ecommerce/FreeShippingView.vue");
const ShippingTimeView = () =>
  import("../views/ecommerce/ShippingTimeView.vue");
const ToBeShippedView = () => import("../views/ecommerce/ToBeShippedView");
const PnLView = () => import("../views/PnLView.vue");
const CashflowView = () => import("../views/CashflowView.vue");
const SalesView = () => import("../views/SalesView.vue");
const GlobalSales = () => import("../views/GlobalSales.vue");
const InfluenceurView = () => import("../views/InfluenceurView.vue");
const UploadOrder = () => import("../views/order/UploadOrder.vue");
const ArcView = () => import("../views/inventory/ArcView");
const LteView = () => import("../views/inventory/LteView");
const SnjjView = () => import("../views/inventory/SnjjView");
const TransferView = () => import("../views/inventory/TransferView");
const AssembliesView = () => import("../views/inventory/AssembliesView");
const AssemblyLinesView = () => import("../views/inventory/AssemblyLinesView");
const OfficeView = () => import("../views/inventory/OfficeView");
const DataBaseView = () => import("../views/inventory/DataBaseView");
const AchatView = () => import("../views/finance/AchatView");
const DailyCashflowView = () => import("../views/finance/DailyCashflowView");
const BQ1View = () => import("../views/finance/BQ1View");
const BQ5View = () => import("../views/finance/BQ5View");
const ODView = () => import("../views/finance/ODView");
const SGIView = () => import("../views/finance/SGIView");
const TresoView = () => import("../views/finance/TresoView");
const VenteView = () => import("../views/finance/VenteView");
const UserLogin = () => import("../components/UserLogin.vue");
const NewsletterView = () =>
  import("../views/communication/NewsletterView.vue");
const NewsletterCodeView = () =>
  import("../views/communication/NewsletterCodeView.vue");
const NewsletterAnalysisView = () =>
  import("../views/communication/NewsletterAnalysisView.vue");
const CalendrierView = () => import("../views/cominterne/CalendrierView.vue");
const TodoView = () => import("../views/cominterne/TodoView.vue");
const MealPlanner = () => import("../views/cominterne/MealPlanner.vue");
const ShippingSamplesView = () =>
  import("../views/communication/ShippingSamplesView.vue");

const routes = [
  {
    path: "/inventory",
    name: "inventory",
    component: InventoryView,
  },

  {
    path: "/pnl",
    name: "pnl",
    component: PnLView,
  },
  {
    path: "/cashflow",
    name: "cashflow",
    component: CashflowView,
  },
  {
    path: "/sales",
    name: "sales",
    component: SalesView,
  },
  {
    path: "/globalsales",
    name: "global sales",
    component: GlobalSales,
  },
  {
    path: "/influenceur",
    name: "influenceur",
    component: InfluenceurView,
  },
  {
    path: "/order",
    name: "order",
    component: UploadOrder,
  },
  {
    path: "/production/ARC",
    name: "ARC",
    component: ArcView,
  },
  {
    path: "/inventory/ARC",
    name: "SNJJ_PRODUCT",
    component: ArcView,
  },

  {
    path: "/inventory/LTE",
    name: "LTE",
    component: LteView,
  },
  {
    path: "/inventory/SNJJ",
    name: "SNJJ_AC",
    component: SnjjView,
  },
  {
    path: "/inventory/91",
    name: "91",
    component: OfficeView,
  },
  {
    path: "/inventory/database",
    name: "DataBase",
    component: DataBaseView,
  },
  {
    path: "/login",
    name: "Login",
    component: UserLogin,
  },
  {
    path: "/inventory/TRANSFERVIEW",
    name: "TRANSFER",
    component: TransferView,
  },
  {
    path: "/inventory/ASSEMBLIESVIEW",
    name: "ASSEMBLIES",
    component: AssembliesView,
  },
  {
    path: "/inventory/ASSEMBLYLINESVIEW",
    name: "ASSEMBLY LINES",
    component: AssemblyLinesView,
  },
  {
    path: "/comptabilite/achat",
    name: "ACHAT",
    component: AchatView,
  },
  {
    path: "/comptabilite/cashflow",
    name: "SOLDE BANCAIRE",
    component: DailyCashflowView,
  },
  {
    path: "/comptabilite/bq1",
    name: "BQ1",
    component: BQ1View,
  },
  {
    path: "/comptabilite/bq5",
    name: "BQ5",
    component: BQ5View,
  },
  {
    path: "/comptabilite/od",
    name: "OD",
    component: ODView,
  },
  {
    path: "/comptabilite/sgi",
    name: "SGI",
    component: SGIView,
  },
  {
    path: "/comptabilite/treso",
    name: "TRESO",
    component: TresoView,
  },
  {
    path: "/comptabilite/vente",
    name: "VENTE",
    component: VenteView,
  },
  {
    path: "/ecommerce/orderstobeshipped",
    name: "ORDERS",
    component: ToBeShippedView,
  },
  {
    path: "/ecommerce/freeshipping",
    name: "FREE SHIPPING",
    component: FreeShippinghView,
  },
  {
    path: "/ecommerce/shippingtime",
    name: "SHIPPING TIME",
    component: ShippingTimeView,
  },
  {
    path: "/ecommerce/shippinghistory",
    name: "SHIPPING",
    component: ShippingHistoryView,
  },
  {
    path: "/ecommerce/ordersearch",
    name: "ORDERSEARCH",
    component: OrderSearchView,
  },
  {
    path: "/communication/newsletter",
    name: "NEWSLETTER",
    component: NewsletterView,
  },
  {
    path: "/communication/shipping",
    name: "SHIPPING SAMPLES",
    component: ShippingSamplesView,
  },
  {
    path: "/communication/newslettercode",
    name: "NEWSLETTERCODE",
    component: NewsletterCodeView,
  },
  {
    path: "/communication/newsletteranalysis",
    name: "NEWSLETTER ANALYSIS",
    component: NewsletterAnalysisView,
  },
  {
    path: "/cominterne/calendrier",
    name: "CALENDRIER",
    component: CalendrierView,
  },
  {
    path: "/cominterne/todolist",
    name: "TODOLIST",
    component: TodoView,
  },
  {
    path: "/cominterne/mealplanner",
    name: "MEALPLANNER",
    component: MealPlanner,
    meta: { requiresAlexandra: true },
  },
  {
    path: "/cominterne/todolist",
    component: TodoView, // Le composant avec HeaderComInterne et la navigation
    children: [
      {
        path: ":projectName",
        name: "ProjectTasks",
        component: TodoList,
        props: true,
      },
    ],
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const roleBasedAccess = {
  admin: [
    "/order",
    "/globalsales",
    "/comptabilite",
    "/inventory",
    "/ecommerce",
    "/influenceur",
    "/cominterne",
    "/communication",
    "/projects",
  ],
  ecommerce: ["/inventory", "/ecommerce", "/cominterne", "/order", "/projects"],
  compta: ["/comptabilite", "/projects"],
  digital: ["/cominterne", "/communication", "/projects"],
};

async function checkSession() {
  const store = useStore();
  const {
    data: { session },
  } = await supabase.auth.getSession();
  if (session) {
    store.setUser(session.user);
    store.setLoggedIn(true);
    // Restaurer le rôle
    const email = session.user.email;
    let role;
    switch (email) {
      case "alexandra411@gmail.com":
      case "alexandra@unenuitnomade.com":
        role = "admin";
        break;
      case "sigmundphil@gmail.com":
        role = "admin";
        break;
      case "orders@unenuitnomade.com":
        role = "ecommerce";
        break;
      case "compta@unenuitnomade.com":
        role = "compta";
        break;
      case "cubizollesmajory@gmail.com":
      case "mehreen@unenuitnomade.com":
        role = "digital";
        break;
      default:
        role = "user";
    }
    store.setRole(role);
  } else {
    store.clearUser(); // Optionnel : pour être sûr de ne pas avoir d'état erroné
  }
}

router.beforeEach(async (to, from, next) => {
  const store = useStore();

  // Attendre que la session soit vérifiée avant de continuer
  await checkSession();

  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = store.loggedIn;
  const role = store.role;
  const userEmail = store.user?.email;

  console.log(`Path: ${to.path}`);
  console.log(`authRequired: ${authRequired}`);
  console.log(`loggedIn: ${loggedIn}`);
  console.log(`Role: ${role}`);
  console.log(`UserEmail: ${userEmail}`);

  if (authRequired && !loggedIn) {
    return next("/login");
  }

  if (loggedIn && to.path === "/login") {
    if (role === "compta") {
      router.push("/comptabilite/achat");
    } else if (role === "digital" || role === "admin" || role === "admin2") {
      router.push("cominterne/todolist");
    } else if (role === "ecommerce") {
      router.push("ecommerce/orderstobeshipped");
    } else {
      router.push("/cominterne/todolist");
    }
  }

  if (loggedIn) {
    // Vérification spécifique pour la route MealPlanner
    if (to.meta.requiresAlexandra && userEmail !== "alexandra411@gmail.com") {
      console.log(
        `Unauthorized access attempt to ${to.path} by ${userEmail}. Redirecting.`,
      );
      return next("/inventory/91");
    }

    const allowedPaths = roleBasedAccess[role] || [];
    const isAuthorized = allowedPaths.some((path) => to.path.startsWith(path));

    if (!isAuthorized) {
      console.log(`Unauthorized access attempt to ${to.path}. Redirecting.`);
      return next("/inventory/91");
    }
  }

  next();
});

export default router;
